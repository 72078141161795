import React from "react";

const CompareIcon = () => {
  return (
    <>
      <svg
        id="Layer_1"
        enableBackground="new 0 0 512 512"
        height="20"
        viewBox="0 0 512 512"
        width="20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="m333.412 335.223-39.923 39.924c51.891-16.308 87.384-64.53 87.384-119.147 0-28.575-9.237-55.33-27.037-77.707l50-39.75c26.474 33.281 41.037 74.929 41.037 117.458 0 85.5-57.507 160.34-139.783 182.427l28.323 28.323-45.251 45.249-111.013-111.013 111.013-111.014zm-118.97-197.014-38.568 38.568 45.25 45.25 111.013-111.013-111.013-111.014-45.25 45.25 28.908 28.908c-81.223 22.834-137.655 97.151-137.655 181.842 0 44.17 15.539 86.831 43.81 120.749l49-40.75c-18.853-22.62-28.81-50.583-28.81-79.998 0-53.056 33.503-100.221 83.315-117.792z"
          fillRule="evenodd"
        />
      </svg>
    </>
  );
};

export default CompareIcon;
