import { MenuType } from "@/interFace/interFace";

const menu_data: MenuType[] = [
  {
    id: 1,
    hasDropdown: false,
    active: true,
    title: "HOME",
    pluseIncon: true,
    link: "/",
    // submenus: [
    //   { title: "Home 1", link: "/" },
    //   { title: "Home 2", link: "/home-2" },
    //   { title: "Home 3", link: "/home-3" },
    // ],
  },
  {
    id: 2,
    hasDropdown: false,
    megaMenu: true,
    active: true,
    title: "SHOP ALL",
    pluseIncon: true,
    link: "/shop",
    submenus: [
      {
        title: "Shop",
        link: "#",
        megaMenu: [
          { title: "Shop Sidebar 5 Column", link: "/shop-sidebar-5-column" },
          { title: "Shop Sidebar 4 Column", link: "/shop-sidebar-4-column" },
          { title: "Shop Sidebar 3 Column", link: "/shop-sidebar-3-column" },
          { title: "Shop Full 6 Column", link: "/shop-full-6-column" },
          { title: "Shop Full 5 Column", link: "/shop-full-5-column" },
          { title: "Shop Full 4 Column", link: "/shop-full-4-column" },
          { title: "Group Product", link: "/group-product" },
        ],
      },
      // { title: "Shop Details", link: "/shop-details" },
      // { title: "Wishlist", link: "/wishlist" },
      // { title: "Cart", link: "/cart" },
      // { title: "Compare", link: "/compare" },
      // { title: "Checkout", link: "/checkout" },
    ],
  },
  
  {
    id: 4,
    hasDropdown: false,
    active: true,
    title: "BIG RAMY",
    pluseIncon: true,
    link: "/big-ramy",
   
  },
  {
    id: 5,
    hasDropdown: false,
    active: true,
    title: "DRAGON",
    pluseIncon: true,
    link: "/dragon",
  },
  {
    id: 8,
    hasDropdown: false,
    active: true,
    title: "KO KEAR",
    pluseIncon: true,
    link: "/kogear",
  },

  {
    id: 6,
    hasDropdown: false,
    active: true,
    title: "LIMITED EDITION",
    pluseIncon: true,
    link: "/limited-ed",
  },
  
  {
    id: 7,
    hasDropdown: false,
    active: true,
    title: "ABOUT ",
    pluseIncon: true,
    link: "/about",
  },
  // {
  //   id: 4,
  //   hasDropdown: true,
  //   active: true,
  //   title: "Blog",
  //   pluseIncon: true,
  //   link: "#",
  //   submenus: [
  //     { title: "Blog", link: "/blog" },
  //     { title: "Blog Details", link: "/blog-details" },
  //   ],
  // },

  // {
  //   id: 6,
  //   hasDropdown: false,
  //   active: true,
  //   title: "Contact",
  //   link: "/contact",
  // },
];

export default menu_data;
